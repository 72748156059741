import React from 'react';

//Images
import Logo from '../../../assets/img/logo.svg';

//Material UI
import Grid from '@material-ui/core/Grid';

//Hooks
import useAuth from '../../../hooks/useAuth';

//Components
import FormRecoverAccount from '../../../components/organisms/FormRecoverAccount';

export default function RecoverAccount(props) {
  const user = useAuth();
  if (user) {
    window.location.href = props.history.goBack();
  } else {
    return (
      <div className='mailsent-container'>
        <div className='card--large'>
          <img
            className='pt-5 mb-5 logo d-block mx-auto'
            src={Logo}
            alt='Wikicid Logo'
          />
          <h3 className='h3 text-center mb-3 a-text-bold-second-neutral-100'>Recupera tu cuenta</h3>
          <p className='text-center text-normal a-text-regular-second-neutral-60 mb-3'>Ingresa tu correo y te enviaremos un enlace para recuperar tu contraseña.</p>
          <FormRecoverAccount />
        </div>
      </div>
    );
  }
}
